<template>
  <transition name="popup">
    <div class="popup" v-if="isShow2">
      <div class="qrcode-wrap">
        <div class="qrcode-box">
          <div class="tips">扫码填写</div>
          <div class="title">获取产品价格和资料信息</div>
          <div class="qrcode">
            <img src="../assets/enquiryqrcode.jpg" alt="" />
          </div>
          <div class="optionTips">长按二维码识别或截图保存</div>
        </div>
        <div class="close">
          <i class="iconfont icon-close" @click="close"></i>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isShow: {
      typeof: Boolean,
      default: false,
    },
    qrcode: {
      typeof: String,
      require: true,
    },
  },
  data() {
    return {
      isShow2: this.isShow,
    };
  },
  mounted() {},
  watch: {
    isShow() {
      this.isShow2 = this.isShow;
    },
  },
  methods: {
    close() {
      this.isShow2 = false;
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  z-index: 54987;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
  .qrcode-wrap {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    .qrcode-box {
      width: 3rem;
      height: 3.25rem;
      background: url(../assets/enquirybg.png) no-repeat;
      background-size: cover;
      overflow: hidden;
      border-radius: 0.1rem;
      text-align: center;
      .tips {
        margin-top: 0.25rem;
        font-size: 0.16rem;
      }
      .title {
        font-size: 0.14rem;
        font-weight: 700;
        margin-top: 0.1rem;
      }
      .qrcode {
        margin: auto;
        margin-top: 0.4rem;
        width: 1.5rem;
        height: 1.5rem;
        img {
          width: 100%;
          height: 100%;
          -webkit-user-drag: auto;
          -webkit-user-select: auto;
          -moz-user-select: all;
          user-select: auto;
          pointer-events: all;
        }
      }
      .optionTips {
        font-size: 0.12rem;
        margin-top: 0.1rem;
        color: #666;
      }
    }
  }

  .close {
    color: #fff;
    text-align: center;
    margin-top: 0.1rem;
    i {
      cursor: pointer;
      font-size: 0.3rem;
      font-weight: 800;
    }
  }
}
@media screen and (min-width: 1200px) {
  .popup {
    .qrcode-wrap {
      .qrcode-box {
        width: 300px;
        height: 325px;
        border-radius: 10px;
        .tips {
          margin-top: 25px;
          font-size: 16px;
        }
        .title {
          font-size: 14px;
          font-weight: 700;
          margin-top: 10px;
        }
        .qrcode {
          margin-top: 40px;
          width: 150px;
          height: 150px;
        }
        .optionTips {
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }
    .close {
      margin-top: 10px;
      i {
        font-size: 30px;
      }
    }
  }
}
.popup-enter,
.popup-leave-to {
  opacity: 0;
  // transform: scale(0);
}
.popup-enter-active,
.popup-leave-active {
  transition: 0.3s;
}
/* 进入的终点、离开的起点 */
.popup-enter-to,
.popup-leave {
  opacity: 1;
  // transform: scale(1);
}
@font-face {
  font-family: "iconfont"; /* Project id 2931857 */
  src: url("//at.alicdn.com/t/font_2931857_z53a5jno03.woff2?t=1636625030190")
      format("woff2"),
    url("//at.alicdn.com/t/font_2931857_z53a5jno03.woff?t=1636625030190")
      format("woff"),
    url("//at.alicdn.com/t/font_2931857_z53a5jno03.ttf?t=1636625030190")
      format("truetype");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e6a7";
}
</style>
