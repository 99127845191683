<template>
  <div class="clause-pc">
    <pc-header style="margin-bottom: 30px"></pc-header>
    <div class="clause-box">
      <div class="title">Illumina因美纳信息注册知情同意书</div>
      <div class="txt-box">
        <div class="title-two">
          Consent Interface for Online Leads Generation Platforms<br />
          线上平台客户信息收集同意界面
        </div>
        <div class="txt">
          <pre>
By filling out the form, you will need to provide the following personal information to Illumina:
通过提交本表格，您同意向因美纳提供如下个人信息：

Name, organization, job function and contact information (email address and/or mobile number).
姓名、单位、工作职能部门和联系方式（电子邮箱地址和/或手机号码）。

Illumina will view you as a potential customer and such information will help us contact you about our products and services.
因美纳会将您视为潜在客户，以上信息有助于我们就产品和服务联系您。

Illumina engages Salesforce.com, Inc. (“Salesforce”) globally to provide customer relationship management services. Illumina also engages SAP SE (“SAP”) globally to
provide enterprise resources planning services. In performing these services both Salesforce and SAP will need to receive and store data from Illumina’s customers. Therefore,
your information above will be transferred, stored, and/or deleted through the Illumina Salesforce and/or SAP system designed and operated by Salesforce and/or SAP 
for Illumina. The Illumina Salesforce and/or SAPsystem is located in an overseas server or an overseas cloud platform. 
因美纳在全球范围内使用Salesforce.com, Inc.（“Salesforce”）为其提供客户关系管理服务。因美纳还在全球范围内使用SAP SE (“SAP”) 为其提供企业资源规划服务。为提供
这些服务, Salesforce和SAP需要从因美纳的客户处接收和存储数据。因此，您的如上信息会通过Salesforce和/或SAP公司为因美纳设计及运行的因美纳的Salesforce和/或SAP
系统被转移、存贮和/或删除。因美纳的Salesforce和/或SAP系统设置于中国境外的服务器或境外的云平台。

If you choose not to provide or do not agree to our collection and use of the above personal information, Illumina will not be able to contact you.
如您选择不提供或不同意我们收集和使用上述个人信息，因美纳将无法联系您。

If you would like to access, correct, restrict or remove any personal information that is stored in the Illumina Salesforce and/or SAP system, you may contact <a href="mailto:privacy@illumina.com">privacy@illumina.com</a>. 
如您想要查看、更正、限制或移除任何储存在因美纳的Salesforce和/或SAP系统上的任何个人信息，您可以直接联系<a href="mailto:privacy@illumina.com">privacy@illumina.com</a>。

For more information on how Illumina protects your privacy and personal information, please refer to Illumina’s Privacy Policy available at <a href="https://www.illumina.com/company/legal/privacy.html">https://www.illumina.com/com
pany/legal/privacy.html</a>   
如要获取更多的关于因美纳如何保护您的隐私和个人信息的相关消息，请点击<a href="https://www.illumina.com/company/legal/privacy.html">https://www.illumina.com/company/legal/privacy.html</a>查看因美纳隐私政策。
          </pre>
          <div class="select">
            <input type="checkbox" v-model="isSelect" />
            <div class="txt">
              <pre>
I have read and understood the above and agree to the collection, transfer, storage and use of my personal information by Illumina through Salesforce and/or SAP.
我已经阅读并理解上述内容并同意因美纳通过Salesforce和/或SAP系统收集、转移、存储及使用我的个人信息。
              </pre>
            </div>
          </div>
        </div>
        <button
          class="register-btn"
          :disabled="!isSelect"
          :class="isSelect ? 'color1' : 'color2'"
          @click="toRegister()"
        >
          注册并登录
        </button>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "../../components/pc-header.vue";
import PcFooter from "../../components/pc-footer.vue";
export default {
  components: { PcHeader, PcFooter },
  data() {
    return {
      isSelect: false,
    };
  },
  methods: {
    toRegister() {
      sessionStorage.setItem("clause", true);
      this.$router.replace({
        path: "/register",
        query: {
          r: this.$route.query.r ? this.$route.query.r : "",
          c: this.$route.query.c ? this.$route.query.c : "",
          w: this.$route.query.w ? this.$route.query.w : "",
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.clause-pc {
  height: 100%;
  background: url(../../assets/pcbg.png) 0 0 no-repeat;
  background-size: 100% auto;
  .clause-box {
    width: 1200px;
    height: 790px;
    background-color: #fff;
    margin: 0 auto 80px;
    .txt-box {
      padding: 35px 21px 30px 87px;
      box-sizing: border-box;
      .title-two {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 25px;
      }
      .txt {
        font-size: 14px;
        font-weight: 500;
        p {
          white-space: pre-line;
        }
        pre {
          font-family: "SourceHanSansCN", "Arial Narrow", Arial, sans-serif;
          margin: 0;
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
  .title {
    width: 100%;
    padding-left: 30px;
    height: 57px;
    line-height: 57px;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #ececec;
    box-sizing: border-box;
  }
  .select {
    display: flex;
    input {
      margin-right: 10px;
    }
  }
  .register-btn {
    font-size: 14px;
    text-align: center;
    width: 145px;
    color: #fff;
    // background-color: #1872bb;
    height: 39px;
    line-height: 39px;
    border-radius: 20px;
    margin: auto;
    margin-top: 30px;
    border: 0;
    display: block;
    // cursor: pointer;
  }

  .color1 {
    background-color: #1872bb;
    cursor: pointer;
  }
  .color2 {
    background-color: #c7c7c7;
    // pointer-events: none;
    cursor: not-allowed;
  }
  .color1:hover {
    background-color: #1a7fd1;
  }
}
</style>
