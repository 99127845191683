<template>
  <div class="page-view">
    <clause-mob v-if="isMobile"></clause-mob>
    <clause-pc v-else></clause-pc>
  </div>
</template>

<script>
import ClausePc from "./clause-pc.vue";
import ClauseMob from "./clause-mob.vue";
export default {
  components: {
    ClausePc,
    ClauseMob,
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
};
</script>
